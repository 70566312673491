<template>
  <div class="login login-v1">
    <div class="login-container">
      <div class="login-header">
        <div class="brand">
          <span>Candles</span>
        </div>
        <div class="icon">
          <i class="fa fa-lock"></i>
        </div>
      </div>
      <div class="login-body">
        <div class="login-content">
          <b-form class="login-form" @submit.prevent="submitLogin">
            <b-form-input
              type="text"
              v-model="loginDetails.login"
              required
              placeholder="Email Address"
              size="lg"
              class="m-b-15"
            />
            <b-form-input
              type="password"
              v-model="loginDetails.password"
              required
              placeholder="Password"
              size="lg"
              class="m-b-15"
            />

            <b-alert variant="danger" :show="errorMessage.length">{{ errorMessage }}</b-alert>

            <div class="login-buttons">
              <b-button type="submit" variant="primary" size="lg" block class="position-relative">
                <span>Sign me in</span>
                <span v-if="loadingCount > 0" class="spinner-small"></span>
              </b-button>
            </div>
          </b-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SignIn',
  components: {},
  data() {
    return {
      account: this.$store.state.account,
      loadingCount: 0,
      errorMessage: '',
      loginDetails: {
        login: '',
        password: '',
      },
    };
  },
  methods: {
    submitLogin() {
      if (this.loadingCount > 0) return;
      this.loadingCount += 1;
      this.errorMessage = '';
      this.$http
        .post('/account/login')
        .send({ name: 'loginDetails', loginDetails: this.loginDetails })
        .then((res) => {
          this.account = Object.assign(this.account, res.body.account);
          if (this.$route.query.redirect) {
            this.$router.push(this.$route.query.redirect);
          } else {
            this.$router.push(this.$store.state.app.defaultRoute);
          }
        })
        .catch((err) => {
          this.errorMessage = err.response.body.status;
        })
        .finally(() => {
          this.loadingCount -= 1;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
